import {
  Binder,
  BinderWithItems,
  Folder,
  ModifyBinderFolderParamsBody,
  NewBinderFolderParamsBody,
  NewWebBookmarkParamsBody,
  ModifyHighlightParamsBody,
  NewPdfBookmarkParamsBody,
  NewBinderParamsBody,
  ModifyBinderParamsBody,
} from 'wklr-backend-sdk/models';
import { codePointCount } from '@/utils/stringUtils';
import { HighlightColorEnum } from 'wklr-backend-sdk/models';
import { BinderItem } from '@/types/binder-folders';

const highlightColorCode = (color: HighlightColorEnum): string => {
  switch (color) {
    case HighlightColorEnum.NUMBER_0:
      return '#b3e5fc';
    case HighlightColorEnum.NUMBER_1:
      return '#f8bcc2';
    case HighlightColorEnum.NUMBER_2:
      return '#b2dfdb';
    case HighlightColorEnum.NUMBER_3:
      return '#e5cdf9';
  }
  return '';
};

export const highlightColorName = (color: HighlightColorEnum): string => {
  switch (color) {
    case HighlightColorEnum.NUMBER_0:
      return 'blue';
    case HighlightColorEnum.NUMBER_1:
      return 'red';
    case HighlightColorEnum.NUMBER_2:
      return 'green';
    case HighlightColorEnum.NUMBER_3:
      return 'purple';
  }
  return '';
};

type HighlightColorOption = { id: HighlightColorEnum; colorCode: string; colorName: string };

/** ハイライトカラーと API で管理している ID のマッピング */
export const highlightColorItems: HighlightColorOption[] = [
  { id: HighlightColorEnum.NUMBER_0, colorCode: highlightColorCode(0), colorName: highlightColorName(0) },
  { id: HighlightColorEnum.NUMBER_1, colorCode: highlightColorCode(1), colorName: highlightColorName(1) },
  { id: HighlightColorEnum.NUMBER_2, colorCode: highlightColorCode(2), colorName: highlightColorName(2) },
  { id: HighlightColorEnum.NUMBER_3, colorCode: highlightColorCode(3), colorName: highlightColorName(3) },
];

export const sorterDocTitle = (lhv: BinderItem, rhv: BinderItem): -1 | 0 | 1 => {
  if (lhv.doc.title < rhv.doc.title) return -1;
  if (lhv.doc.title > rhv.doc.title) return 1;
  return 0;
};

const FOLDER_NAME_MAX_LENGTH = 64;
const FOLDER_NAME_TOO_SHORT_ERROR_MESSAGE = 'フォルダーの名前は空にはできません';
const FOLDER_NAME_TOO_LONG_ERROR_MESSAGE = 'フォルダーの名前に指定できるのは64文字までです';

export const folderNameValidator = (name: string): true | string => {
  if (name.length === 0) {
    return FOLDER_NAME_TOO_SHORT_ERROR_MESSAGE;
  }
  if (codePointCount(name) > FOLDER_NAME_MAX_LENGTH) {
    return FOLDER_NAME_TOO_LONG_ERROR_MESSAGE;
  }
  return true;
};

const BINDER_NAME_MAX_LENGTH = 256;
const BINDER_NAME_TOO_SHORT_ERROR_MESSAGE = 'バインダーの名前は空にはできません';
const BINDER_NAME_TOO_LONG_ERROR_MESSAGE = 'バインダーの名前に指定できるのは256文字までです';

export const binderNameValidator = (name: string): true | string => {
  if (name.length === 0) {
    return BINDER_NAME_TOO_SHORT_ERROR_MESSAGE;
  }
  if (codePointCount(name) > BINDER_NAME_MAX_LENGTH) {
    return BINDER_NAME_TOO_LONG_ERROR_MESSAGE;
  }
  return true;
};

// このメモ (description) の制約は、バインダー、ブックマーク、ハイライトで共通である
const DESCRIPTION_MAX_LENGTH = 1024;
const DESCRIPTION_LENGTH_ERROR_MESSAGE = 'メモに指定できるのは1024文字までです';

export const descriptionValidator = (description: string): true | string => {
  if (codePointCount(description) > DESCRIPTION_MAX_LENGTH) {
    return DESCRIPTION_LENGTH_ERROR_MESSAGE;
  }
  return true;
};

/**
 * folder 関連の API のリクエストの内容をバリデーションして問題なければデータを返す
 * @throws
 */
export const guardFolderRequestData = <T extends ModifyBinderFolderParamsBody | NewBinderFolderParamsBody>(
  data: T,
): T => {
  if (data.name.length === 0) throw new Error(FOLDER_NAME_TOO_SHORT_ERROR_MESSAGE);
  if (codePointCount(data.name) > FOLDER_NAME_MAX_LENGTH) throw new Error(FOLDER_NAME_TOO_LONG_ERROR_MESSAGE);
  return data;
};

/**
 * binder 関連の API のリクエストの内容をバリデーションして問題なければデータを返す
 * @throws
 */
export const guardBinderRequestData = <T extends NewBinderParamsBody | ModifyBinderParamsBody>(data: T): T => {
  if (data.name.length === 0) throw new Error(BINDER_NAME_TOO_SHORT_ERROR_MESSAGE);
  if (codePointCount(data.name) > BINDER_NAME_MAX_LENGTH) throw new Error(BINDER_NAME_TOO_LONG_ERROR_MESSAGE);
  if (data.description && codePointCount(data.description) > DESCRIPTION_MAX_LENGTH)
    throw new Error(DESCRIPTION_LENGTH_ERROR_MESSAGE);
  return data;
};

/**
 * binder item = ハイライト・ブックマーク関連の API のリクエストの内容をバリデーションして問題なければデータを返す
 * @throws
 */
export const guardBinderItemRequestData = <
  T extends NewWebBookmarkParamsBody | ModifyHighlightParamsBody | NewPdfBookmarkParamsBody,
>(
  data: T,
): T => {
  if (codePointCount(data.description) > DESCRIPTION_MAX_LENGTH) throw new Error(DESCRIPTION_LENGTH_ERROR_MESSAGE);
  return data;
};

/**
 * バインダーのアイテム一覧をパースする
 * @param data: BinderItemsResponse
 * @returns
 */
export const parseBinderItemsResponse = (
  data: BinderWithItems,
): { binder: Binder; folder?: Folder; items: BinderItem[] } => {
  const { binder, bookmarks, folder, highlights } = data;
  const items = [
    ...bookmarks.map((item) => ({ type: 'bookmark' as const, ...item })),
    ...highlights.map((item) => ({ type: 'highlight' as const, ...item })),
  ];

  return { binder, folder, items };
};
