import { kanji2number, findKanjiNumbers } from '@geolonia/japanese-numeral';

/**
 * 文字列を構成するコードポイントの個数を返却します。
 *
 * サロゲートペアなどを含まない文字列であれば str.length と同じ値になります。
 */
export const codePointCount = (str: string): number => {
  return Array.from(str).length;
};

/**
 * 文字列中の漢数字をアラビア数字に置換した文字列を返す
 *
 * @param str 漢数字を含んでいる（であろう）文字列
 */
export const replaceKanjiNumbersToNumeric = (str: string) =>
  findKanjiNumbers(str).reduce((acc, kNumber) => acc.replace(kNumber, kanji2number(kNumber).toString()), str);

/**
 * 文字列中の全角数字を半角数字に置換した文字列を返す
 *
 * @param str 全角数字を含んでいる（であろう）文字列
 */
export const replaceZenkakuNumbersToNumeric = (str: string) =>
  str.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
