<template>
  <v-card
    elevation="0"
    class="folder-item-card"
    :class="isSelected && '-selected'"
    :color="content.itemType === 'binder' ? `-${highlightColorName(content.highlightColor)}` : '-folder'"
    @click="$emit('click')"
  >
    <section class="main">
      <v-icon left size="21" class="type">{{ getIcon(content.itemType) }}</v-icon>
      <p class="title">{{ content.name }}</p>
      <v-menu v-if="content.itemType === 'folder'" offset-y>
        <template #activator="{ on }">
          <v-icon class="menu" v-on="on">mdi-dots-horizontal</v-icon>
        </template>
        <v-list dense>
          <v-list-item @click="$emit('click-change-name')">
            <v-icon left size="18">mdi-pencil</v-icon>
            <v-list-item-content>
              <v-list-item-title> 名前を変更 </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('click-delete')">
            <v-icon left size="18">mdi-delete</v-icon>
            <v-list-item-content>
              <v-list-item-title> 削除 </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </section>
    <section class="sub">
      <p class="summary"><!-- TODO: API が対応したら内容物のサマリーを表示する --></p>
      <p class="datetime">{{ formatYmd(new Date(content.updatedAt * 1000)) }}</p>
    </section>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { highlightColorName } from '@/utils/binderFoldersUtils';
import { BinderContents } from '@/types/binder-folders';
import { formatYmd } from '@/utility';

@Component
export default class FolderItem extends Vue {
  formatYmd = formatYmd;
  highlightColorName = highlightColorName;

  @Prop() content!: BinderContents;
  @Prop() isSelected!: boolean;

  getIcon(itemType: 'folder' | 'binder'): string {
    return {
      binder: 'mdi-clipboard-outline',
      folder: 'mdi-folder-outline',
    }[itemType];
  }
}
</script>

<style lang="scss" scoped>
.folder-item-card {
  margin: 20px 16px;
  padding: 16px 16px 16px 8px;
  border: 1px solid #e0e0e0;
  border-left-width: 8px;
  transition: background 0.2s linear;

  &:hover {
    background: #fafafa;
  }

  &.-blue {
    border-left-color: $highlight-blue;
  }

  &.-red {
    border-left-color: $highlight-red;
  }

  &.-green {
    border-left-color: $highlight-green;
  }

  &.-purple {
    border-left-color: $highlight-purple;
  }

  &.-folder {
    border-left-width: 1px;
    padding-left: 15px;
  }

  &.-selected {
    background: #e0e0e0;
  }

  > .main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > .type {
      // Vuetify のスタイルを上書き
      &.v-icon {
        color: rgba(0, 0, 0, 0.87);
        margin-top: -2px;
      }
      // Vuetify のスタイルを上書き
    }

    > .title {
      flex-grow: 1;

      font-size: 16px !important;
      margin: 0;
      padding: 0;
      line-height: 18px;
    }

    > .menu {
      // Vuetify のスタイルを上書き
      &.v-icon {
        margin-top: -5px;
        margin-left: 6px;
      }
      // Vuetify のスタイルを上書き
    }
  }

  > .sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;

    > .summary,
    > .datetime {
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: #8f8f8f;
    }
  }
}
</style>
