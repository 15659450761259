<template>
  <section class="folder-column">
    <section class="column-header">
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn text height="32" v-on="on">
            <v-icon size="18">mdi-plus</v-icon>
            新規作成
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="$emit('click-create-folder')">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon left size="18">mdi-folder-outline</v-icon>
                新しいフォルダー
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="$emit('click-create-binder')">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon left size="18">mdi-clipboard-outline</v-icon>
                新しいバインダー
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <order-selector v-model="folderOrder" :order-map="orderMap" />
    </section>
    <section class="column-container">
      <folder-item
        v-for="content in orderedContents"
        :key="`${content.itemType}-${content.id}`"
        :content="content"
        :is-selected="isSelected(content)"
        @click="contentSelectHandler(content)"
        @click-change-name="changeContentNameHandler(content)"
        @click-delete="deleteContentHandler(content)"
      />
      <p v-if="contents.length < 1" class="empty">フォルダーが空です</p>
    </section>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import OrderSelector, { OrderType } from '@/components/common/order-selector.vue';
import FolderItem from '@/components/binderFolders/folder-item.vue';
import { byKeySorter } from '@/utils/arrayUtil';
import { BinderContents } from '@/types/binder-folders';

const sorterUpdatedAt = byKeySorter<BinderContents>('updatedAt');
const sorterName = byKeySorter<BinderContents>('name');
type OrderVariant = 'updatedAt-asc' | 'updatedAt-desc' | 'name-asc' | 'name-desc';
const ORDER_MAP: OrderType<OrderVariant>[] = [
  {
    label: '更新日の新しい順',
    value: 'updatedAt-desc',
  },
  {
    label: '更新日の古い順',
    value: 'updatedAt-asc',
  },
  {
    label: '名前の昇順（文字コード）',
    value: 'name-asc',
  },
  {
    label: '名前の降順（文字コード）',
    value: 'name-desc',
  },
];

export const DEFAULT_FOLDER_ORDER = ORDER_MAP[0];

@Component({ components: { FolderItem, OrderSelector } })
export default class FolderColumn extends Vue {
  @Prop() contents!: BinderContents[];

  selected: BinderContents | null = null;

  /** バインダー内の並び順のモデル */
  folderOrder = DEFAULT_FOLDER_ORDER;

  /** 選択可能な並び順 */
  readonly orderMap = ORDER_MAP;

  get orderedContents(): BinderContents[] {
    const items = [...this.contents];
    switch (this.folderOrder.value) {
      case 'updatedAt-desc':
        items.sort(sorterUpdatedAt).reverse();
        break;
      case 'updatedAt-asc':
        items.sort(sorterUpdatedAt);
        break;
      case 'name-asc':
        items.sort(sorterName);
        break;
      case 'name-desc':
        items.sort(sorterName).reverse();
        break;
    }
    return items;
  }

  isSelected(content: BinderContents): boolean {
    if (this.selected === null) return false;
    return this.selected.itemType === content.itemType && this.selected.id === content.id;
  }

  contentSelectHandler(content: BinderContents): void {
    this.selected = content;
    this.$emit('click-binder-content', content);
  }

  changeContentNameHandler(content: BinderContents): void {
    this.$emit('click-change-name', content);
  }

  deleteContentHandler(content: BinderContents): void {
    this.selected = content;
    this.$emit('click-delete', content);
  }
}
</script>

<style lang="scss" scoped>
.folder-column {
  border-right: 1px solid #e0e0e0;

  .column-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: white;
    border-bottom: 1px solid #e0e0e0;

    // Vuetify のスタイルを上書き
    .v-btn {
      padding: 0 8px !important;

      .v-icon {
        margin-right: 6px;
      }
    }
    // Vuetify のスタイルを上書き
  }

  .column-container {
    transition: height $default-transition-duration linear;

    height: calc(100vh - 120px - var(--header-area-offset)); // 120px はフォルダーエクスプローラのヘッダなどの高さの合計
    overflow-y: scroll;

    > .empty {
      padding: 20px 16px;
      color: #8f8f8f;
    }
  }
}
</style>
