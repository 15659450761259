<template>
  <layout-normal>
    <template #main>
      <h2 class="heading">クイックアクセス</h2>
      <div style="margin-bottom: 20px">
        <quick-access />
      </div>

      <template v-if="showBinder">
        <h2 class="heading">バインダー</h2>
        <folder-explorer />
      </template>
    </template>
  </layout-normal>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import DocumentListItem from '@/components/document-list-item.vue';
import FolderExplorer from '@/components/folder-explorer.vue';
import LayoutNormal from '@/components/base/layout-normal.vue';
import QuickAccess from '@/components/quick-access.vue';
import { MetaInfo } from 'vue-meta';

@Component({ components: { FolderExplorer, DocumentListItem, LayoutNormal, QuickAccess } })
export default class Collections extends Vue {
  head(): MetaInfo {
    return { title: 'マイ・コレクション' };
  }

  get showBinder(): boolean {
    return this.$auth.permissions.binder;
  }
}
</script>

<style lang="scss" scoped>
.heading {
  margin-bottom: 16px;
  padding: 15px 0;
  font-size: 1.6rem;
}
</style>
