<template>
  <confirm-dialog
    v-model="deleteItemDialog"
    :title="'クイックアクセスから削除'"
    confirm-label="削除する"
    :message="'このアイテムをクイックアクセスから削除します'"
    @confirm="deleteItem"
  >
    <!-- TODO: ここにブックマークの詳細情報を表示する -->
  </confirm-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import { QuickAccessItemWithBibliography } from 'wklr-backend-sdk/models';

@Component({
  components: { ConfirmDialog },
})
export default class DeleteQuickAccessItemDialog extends Vue {
  @Prop()
  success?: () => Promise<void>;

  itemToDelete: QuickAccessItemWithBibliography | null = null;

  get deleteItemDialog(): boolean {
    return this.itemToDelete !== null;
  }
  set deleteItemDialog(value) {
    if (!value) {
      this.itemToDelete = null;
    }
  }

  show(item: QuickAccessItemWithBibliography) {
    this.itemToDelete = item;
  }

  async deleteItem() {
    if (!this.itemToDelete) {
      return;
    }

    try {
      await this.$repositories.quickAccess.deleteItem(this.itemToDelete.id, this.itemToDelete.updatedAt);
      if (this.success) {
        this.success();
      }
      this.$toast.success('クイックアクセスから削除しました');
    } catch (error) {
      console.error(error);
      this.$toast.error('クイックアクセスからの削除に失敗しました');
    } finally {
      this.deleteItemDialog = false;
    }
  }
}
</script>
