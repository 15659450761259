<template>
  <v-dialog v-model="model" width="580" @click:outside="cancelHandler">
    <v-card>
      <v-card-title class="headline grey lighten-2"
        ><v-icon v-if="icon" left>{{ icon }}</v-icon> {{ title }}
      </v-card-title>

      <v-card-text v-if="$slots.default" class="pt-2">
        <slot name="default" />
      </v-card-text>

      <v-divider v-if="$slots.default" />

      <v-card-text>
        <p class="mt-2 mb-2">{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="normal" @click="clickHandler">
          {{ label }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
@Component
export default class AlertDialog extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) message!: string;
  @Prop() icon!: string;

  get model(): boolean {
    return this.value;
  }
  set model(value) {
    this.$emit('input', value);
  }

  cancelHandler() {
    this.$emit('cancel');
    this.model = false;
  }

  clickHandler() {
    this.$emit('click');
    this.model = false;
  }
}
</script>
