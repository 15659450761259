<template>
  <section class="folder-explorer">
    <v-row class="header">
      <v-col class="pa-0">
        <v-btn icon :disabled="isOverflowed" @click="backButtonHandler">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="body">
      <v-row class="bodyWrapper">
        <v-col cols="4" class="column firstColumn" :style="{ marginLeft: leftPosition }">
          <folder-column
            :contents="rootBinderContents"
            @click-create-binder="showCreateBinderDialog(firstColumnKey)"
            @click-create-folder="showCreateFolderDialog(firstColumnKey)"
            @click-binder-content="binderItemClickHandler($event, firstColumnKey)"
            @click-change-name="showEditFolderNameDialog($event, firstColumnKey)"
            @click-delete="showDeleteFolderDialog($event, firstColumnKey)"
          />
        </v-col>
        <v-col v-for="(stack, treeIndex) in treeStack" :key="treeIndex" cols="4" class="column">
          <folder-column
            v-if="stack"
            :contents="stack !== null ? stack.contents : []"
            @click-create-binder="showCreateBinderDialog(stack.parentId)"
            @click-create-folder="showCreateFolderDialog(stack.parentId)"
            @click-binder-content="binderItemClickHandler($event, treeIndex)"
            @click-change-name="showEditFolderNameDialog($event, stack.parentId)"
            @click-delete="showDeleteFolderDialog($event, stack.parentId)"
          />
        </v-col>
      </v-row>
    </div>

    <confirm-dialog
      ref="create-folder-dialog"
      v-model="createFolderDialog"
      title="フォルダを作成"
      confirm-label="作成する"
      message="この内容でフォルダを作成します"
      :disabled="folderNameValidator(editingFolderNameToCreate) !== true"
      @confirm="createFolder"
    >
      <v-text-field
        v-model="editingFolderNameToCreate"
        outlined
        dense
        label="フォルダ名"
        :rules="[folderNameValidator]"
      />
    </confirm-dialog>

    <confirm-dialog
      ref="create-binder-dialog"
      v-model="createBinderDialog"
      title="バインダーを作成"
      confirm-label="作成する"
      message="この内容でバインダーを作成します"
      :disabled="
        binderNameValidator(editingBinderNameToCreateBinder) !== true || selectedHighlightToCreateBinder === null
      "
      @confirm="createBinder"
    >
      <v-text-field
        v-model="editingBinderNameToCreateBinder"
        outlined
        dense
        label="バインダー名"
        :rules="[binderNameValidator]"
      />
      <color-selector v-model="selectedHighlightToCreateBinder" />
    </confirm-dialog>

    <confirm-dialog
      ref="edit-folder-name-dialog"
      v-model="editFolderNameDialog"
      title="フォルダの名前を変更"
      confirm-label="変更する"
      message="フォルダの名前を変更します"
      :disabled="isEditFolderNameDisabled"
      @confirm="editFolderName"
    >
      <v-text-field v-model="editingFolderNameToEdit" label="フォルダ名" outlined :rules="[folderNameValidator]" />
    </confirm-dialog>

    <!-- TODO: 移動機能を実装したタイミングで文言を→に変更する `空でないフォルダは削除できません。先に中に含まれるフォルダ、バインダーを削除もしくは移動してください` -->
    <alert-dialog
      v-model="cannotDeleteFolderDialog"
      title="フォルダを削除"
      label="閉じる"
      message="空でないフォルダは削除できません。先に中に含まれるフォルダ、バインダーを削除してください"
    />

    <confirm-dialog
      v-model="deleteFolderDialog"
      title="フォルダを削除"
      confirm-label="削除する"
      message="このフォルダを削除します"
      @confirm="removeFolder"
    />
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import FolderColumn from '@/components/binderFolders/folder-column.vue';
import AlertDialog from '@/components/common/alert-dialog.vue';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import ColorSelector from '@/components/common/color-selector.vue';
import { folderNameValidator, binderNameValidator } from '@/utils/binderFoldersUtils';
import { BinderContents, BinderContentsBinder, BinderContentsFolder } from '@/types/binder-folders';
import { SESSION_STORAGE_KEY } from '@/constants';
import { HighlightColorEnum } from 'wklr-backend-sdk/models';

const NUMBER_OF_COLUMNS = 3;
const FIRST_COLUMN_KEY = 'binder-first-column';
const FIRST_COLUMN_PARENT_FOLDER_ID = '0';

type TreeStack = {
  parentId: string;
  contents: BinderContents[];
};

@Component({
  components: { AlertDialog, ColorSelector, ConfirmDialog, FolderColumn },
})
export default class FolderExplorer extends Vue {
  $refs!: {
    'create-folder-dialog': ConfirmDialog;
    'create-binder-dialog': ConfirmDialog;
    'edit-folder-name-dialog': ConfirmDialog;
    [key: string]: Vue | Element | Vue[] | Element[];
  };

  folderNameValidator = folderNameValidator;
  binderNameValidator = binderNameValidator;

  /** ルートフォルダのコンテンツの配列 */
  rootBinderContents: BinderContents[] = [];

  /** 詮索されてスタックに乗っているフォルダの配列 */
  treeStack: (TreeStack | null)[] = [null, null];

  /** 対象のカラムがルートフォルダのカラムか判別するためのキー */
  readonly firstColumnKey = FIRST_COLUMN_KEY;

  /** 新規作成するバインダーに適用する色の選択中のもの */
  selectedHighlightToCreateBinder: null | HighlightColorEnum = null;

  /** フォルダ名の作成用データモデル */
  editingFolderNameToCreate = '';

  /** フォルダ新規作成ダイアログの表示用モデル */
  get createFolderDialog(): boolean {
    return this.parentIdOfFolderToCreate !== null;
  }
  set createFolderDialog(value) {
    if (!value) {
      this.parentIdOfFolderToCreate = null;
      this.editingFolderNameToCreate = '';
    }
    console.warn('set parentIdOfFolderToCreate to activate createFolderDialog');
  }

  /** 新規作成するバインダーの名前 */
  editingBinderNameToCreateBinder = '';

  /** バインダー新規作成ダイアログの表示用モデル */
  get createBinderDialog(): boolean {
    return this.parentIdToCreateBinder !== null;
  }
  set createBinderDialog(value) {
    if (!value) {
      this.parentIdToCreateBinder = null;
      this.selectedHighlightToCreateBinder = null;
      this.editingBinderNameToCreateBinder = '';
    }
    console.warn('set parentIdToCreateBinder to activate createBinderDialog');
  }

  /** 新規作成するバインダーを作る親フォルダの ID。 null が入っていると作成されない */
  parentIdOfFolderToCreate: string | null = null;

  /** バインダーを新規作成する先のフォルダ */
  parentIdToCreateBinder: string | null = null;

  /** フォルダ名変更用のモデル */
  folderToEditName: BinderContentsFolder | null = null;

  /** フォルダ名の更新用データモデル */
  editingFolderNameToEdit = '';

  /** フォルダ名更新が有効かどうかのフラグ。名前に変更がない場合は disabled になる */
  get isEditFolderNameDisabled() {
    return this.folderToEditName !== null
      ? folderNameValidator(this.editingFolderNameToEdit) !== true ||
          this.folderToEditName.name === this.editingFolderNameToEdit
      : true;
  }
  /** フォルダ名を変更するするフォルダの親フォルダの ID。 null が入っていると削除されない */
  parentIdOfFolderToEditName: string | null = null;
  get editFolderNameDialog(): boolean {
    return this.folderToEditName !== null;
  }
  set editFolderNameDialog(value) {
    if (!value) {
      this.parentIdOfFolderToEditName = null;
      this.editingFolderNameToEdit = '';
      this.folderToEditName = null;
    }
    console.warn('set folderToEditName to activate editFolderNameDialog');
  }

  /** フォルダ削除用のモデル */
  folderToDelete: BinderContentsFolder | null = null;

  /** 削除するフォルダの親フォルダの ID。 null が入っていると削除されない */
  parentIdOfFolderToDelete: string | null = null;
  get deleteFolderDialog(): boolean {
    return this.folderToDelete !== null;
  }
  set deleteFolderDialog(value) {
    if (!value) {
      this.parentIdOfFolderToDelete = null;
      this.folderToDelete = null;
    }
    console.warn('set folderToDelete to activate deleteFolderDialog');
  }

  /** フォルダの中に要素があると削除できないのでそれを通知するダイアログを表示時するためのモデル */
  cannotDeleteFolderDialog = false;

  async created() {
    this.updateRootFolder();
  }

  async mounted() {
    if (this.$sessionStorage !== null) {
      const folderStackData = this.$sessionStorage.getItem(SESSION_STORAGE_KEY.FOLDER_EXPROLER_TREE_STACK);
      if (folderStackData === null) return;
      const folderStack = folderStackData.split(',');
      // キャッシュの中を確認して存在していたら新しいスタックをリクエストする
      const newStack = await Promise.all(
        folderStack.map(async (key) => {
          try {
            const response = await this.getNewStackFromFolder(key);
            return response;
          } catch (error) {
            console.info('フォルダを取得できませんでした', error);
            return null;
          }
        }),
      );

      // 途中に null がある場合、削除されたと仮定してそこまでの stack を利用する
      // TODO: フォルダの移動などで親子関係が変わった場合も不整合が起こりうるが未対応
      const position = newStack.findIndex((stack) => stack === null);
      const trimmedNewStack = position !== -1 ? newStack.slice(0, position) : newStack;

      // 長さが 2 以下の場合はカラムが真っ白にならないように null を詰める
      this.treeStack = trimmedNewStack.length < 2 ? [...trimmedNewStack, null, null].splice(0, 2) : trimmedNewStack;
    }
  }

  /**
   * フォルダ新規作成ハンドラー
   */
  async createFolder() {
    if (this.editingFolderNameToCreate === '') return;
    const updateTarget = this.parentIdOfFolderToCreate;
    if (updateTarget === null) return;
    const targetFolder = updateTarget === FIRST_COLUMN_PARENT_FOLDER_ID ? null : updateTarget;
    try {
      await this.$repositories.binderFolders.createFolder(targetFolder, this.editingFolderNameToCreate);
      this.updateStackBy(updateTarget);
      this.createFolderDialog = false;
      this.$toast.success('フォルダを作成しました');
    } catch (error) {
      this.$refs['create-folder-dialog'].reactivate();
      console.error(error);
      this.$toast.error('フォルダの作成に失敗しました');
    }
  }

  /**
   * バインダーを新規作成する
   */
  async createBinder() {
    if (this.editingBinderNameToCreateBinder === '') return;
    if (this.selectedHighlightToCreateBinder === null) return;
    const updateTarget = this.parentIdToCreateBinder;
    if (updateTarget === null) return;
    try {
      const description = ''; // TODO: バインダーメモ機能をフロントで実装するタイミングで適用する
      await this.$repositories.binderFolders.createBinder(
        updateTarget,
        this.editingBinderNameToCreateBinder,
        description,
        this.selectedHighlightToCreateBinder!,
      );
      this.updateStackBy(updateTarget);
      this.createBinderDialog = false;
      this.$toast.success('バインダーを作成しました');
    } catch (error) {
      this.$refs['create-binder-dialog'].reactivate();
      console.error(error);
      this.$toast.error('バインダーの作成に失敗しました');
    }
  }

  /**
   * フォルダ名を変更する
   */
  async editFolderName() {
    const updateTarget = this.parentIdOfFolderToEditName;
    if (this.folderToEditName === null || updateTarget === null) return;
    const { id, parentId, updatedAt } = this.folderToEditName;
    try {
      await this.$repositories.binderFolders.editFolder(id, parentId, this.editingFolderNameToEdit, updatedAt);
      this.updateStackBy(updateTarget);
      this.editFolderNameDialog = false;
      this.$toast.success('フォルダ名を変更しました');
    } catch (error) {
      this.$refs['edit-folder-name-dialog'].reactivate();
      console.error(error);
      this.$toast.error('フォルダ名の変更に失敗しました');
    }
  }

  /**
   * フォルダーを削除する
   */
  async removeFolder() {
    const updateTarget = this.parentIdOfFolderToDelete;
    if (this.folderToDelete === null || updateTarget === null) return;
    try {
      await this.$repositories.binderFolders.removeFolder(this.folderToDelete.id, this.folderToDelete.updatedAt);
      this.removeStackBy(updateTarget);
      this.$toast.success('フォルダを削除しました');
    } catch (error) {
      console.error(error);
      this.$toast.error('フォルダの削除に失敗しました');
    } finally {
      this.deleteFolderDialog = false;
    }
  }

  /**
   * 作成対象となる親フォルダを指定してバインダー新規作成のダイアログを表示する
   */
  showCreateBinderDialog(targetFolder: string | typeof FIRST_COLUMN_KEY) {
    this.parentIdToCreateBinder = targetFolder !== FIRST_COLUMN_KEY ? targetFolder : FIRST_COLUMN_PARENT_FOLDER_ID;
  }

  /**
   * 作成対象となる親フォルダを指定してフォルダ新規作成のダイアログを表示する
   */
  showCreateFolderDialog(targetFolder: string | typeof FIRST_COLUMN_KEY) {
    this.parentIdOfFolderToCreate = targetFolder !== FIRST_COLUMN_KEY ? targetFolder : FIRST_COLUMN_PARENT_FOLDER_ID;
    this.createFolderDialog = true;
  }

  /**
   * 編集対象となる親フォルダを指定してフォルダ名変更のダイアログを表示する
   */
  showEditFolderNameDialog(target: BinderContents, targetFolder: string | typeof FIRST_COLUMN_KEY) {
    if (target.itemType !== 'folder') return;
    this.parentIdOfFolderToEditName = targetFolder !== FIRST_COLUMN_KEY ? targetFolder : FIRST_COLUMN_PARENT_FOLDER_ID;
    this.editingFolderNameToEdit = target.name;
    this.folderToEditName = target;
  }

  /**
   * 削除対象となるフォルダを指定してフォルダ削除のダイアログを表示する
   */
  async showDeleteFolderDialog(target: BinderContents, targetFolder: string | typeof FIRST_COLUMN_KEY) {
    if (target.itemType !== 'folder') return;
    try {
      const response = await this.$repositories.binderFolders.getFolderContents(target.id);
      if (response.folders.length > 0 || response.binders.length > 0) {
        this.cannotDeleteFolderDialog = true;
      } else {
        this.parentIdOfFolderToDelete =
          targetFolder !== FIRST_COLUMN_KEY ? targetFolder : FIRST_COLUMN_PARENT_FOLDER_ID;
        this.folderToDelete = target;
      }
    } catch (error) {
      console.error(error);
      this.$toast.error('フォルダ情報の取得に失敗しました。リロードしてやりなおしてください');
    }
  }

  /**
   * 現在のスタックから一番右側を削除する
   */
  backButtonHandler() {
    this.treeStack.pop();
  }

  /**
   * リスト中のバインダーやフォルダをクリックした時の動作
   */
  async binderItemClickHandler(binderContents: BinderContents, index: number | typeof FIRST_COLUMN_KEY) {
    if (binderContents.itemType === 'binder') {
      this.$router.push(`/binder/${binderContents.id}`);
    } else {
      try {
        const newStack = await this.getNewStackFromFolder(binderContents.id);
        if (newStack === null) return;
        if (index === FIRST_COLUMN_KEY) {
          this.treeStack = [newStack, null];
        } else {
          this.treeStack.splice(index + 1);
          this.treeStack.push(newStack);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error('フォルダ情報の取得に失敗しました');
      }
    }
  }

  /**
   * ルートフォルダを API から取得して更新する
   */
  async updateRootFolder() {
    try {
      const response = await this.$repositories.binderFolders.getFolderContents();
      const folders: BinderContentsFolder[] = response.folders.map((item) => ({ itemType: 'folder', ...item }));
      const binders: BinderContentsBinder[] = response.binders.map((item) => ({ itemType: 'binder', ...item }));
      this.rootBinderContents = [...folders, ...binders];
    } catch (error) {
      console.error(error);
      this.$toast.error('フォルダ情報の取得に失敗しました。リロードしてください');
    }
  }

  /**
   * ツリースタックから対応するフォルダを探して、内容を API から取得して更新する
   */
  async updateStackBy(folderId: string) {
    if (folderId === FIRST_COLUMN_PARENT_FOLDER_ID) {
      this.updateRootFolder();
    } else {
      try {
        const newStack = await this.getNewStackFromFolder(folderId);
        this.treeStack = this.treeStack.map((stack) =>
          stack && newStack && stack.parentId === newStack.parentId ? newStack : stack,
        );
      } catch (error) {
        console.error(error);
        this.$toast.error('フォルダ情報の取得に失敗しました。リロードしてください');
      }
    }
  }

  /**
   * ツリースタックから対応するフォルダを探して、それ以降を削除する
   */
  async removeStackBy(folderId: string) {
    if (folderId === FIRST_COLUMN_PARENT_FOLDER_ID) {
      this.treeStack = [null, null];
    } else {
      const position = this.treeStack.findIndex((stack) => stack !== null && stack.parentId === folderId);
      if (position !== -1) {
        this.treeStack = this.treeStack.slice(0, position + 1);
      }
    }
    this.updateStackBy(folderId);
  }

  /**
   * 任意のフォルダを API から取得してスタックに変換する
   * @throws
   */
  async getNewStackFromFolder(folderId: string): Promise<TreeStack | null> {
    const response = await this.$repositories.binderFolders.getFolderContents(folderId);
    const folders: BinderContentsFolder[] = response.folders.map((item) => ({ itemType: 'folder', ...item }));
    const binders: BinderContentsBinder[] = response.binders.map((item) => ({ itemType: 'binder', ...item }));
    return { parentId: folderId, contents: [...folders, ...binders] };
  }

  get isOverflowed(): boolean {
    return this.treeStack.length < NUMBER_OF_COLUMNS;
  }

  get leftPosition(): string {
    const len = this.treeStack.length - NUMBER_OF_COLUMNS + 1;
    return len > 0 ? `-${len * (100 / NUMBER_OF_COLUMNS)}%` : '0';
  }

  @Watch('treeStack')
  stackWatcher() {
    if (this.$sessionStorage !== null) {
      const folderStack = this.treeStack
        .filter((stack): stack is TreeStack => stack !== null)
        .map((stack) => stack.parentId);
      this.$sessionStorage.setItem(SESSION_STORAGE_KEY.FOLDER_EXPROLER_TREE_STACK, folderStack.join(','));
    }
  }
}
</script>

<style lang="scss" scoped>
.folder-explorer {
  margin-bottom: 20px;

  // Vuetify のスタイルを上書き
  .row {
    margin: 0 -12px;
  }
  // Vuetify のスタイルを上書き

  > .header {
    border: solid 1px #ddd;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
  }

  > .body {
    overflow-x: scroll;
    border: solid 1px #ddd;
    margin: 0 -12px;
    border-radius: 0 0 3px 3px;

    > .bodyWrapper {
      flex-wrap: nowrap;
      margin: 0;

      > .column {
        padding: 0;

        &.firstColumn {
          transition: margin 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        }
      }
    }
  }
}
</style>
