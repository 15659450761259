<template>
  <confirm-dialog
    ref="editDescriptionDialog"
    v-model="editDescriptionDialog"
    :title="'このアイテムのメモを変更'"
    confirm-label="変更する"
    message="この内容でメモを変更します"
    :disabled="
      itemToEdit === null ||
      itemToEdit.description === editingDescription ||
      descriptionValidator(editingDescription) !== true
    "
    @confirm="updateBookmarkDescription"
  >
    <div v-if="itemToEdit" class="webview-dialog-body">
      <p>
        文献名 {{ itemToEdit.doc.title }}
        <template v-if="itemToEdit.viewType !== 'document'">
          <br />
          {{ itemToEdit.headingText }}
        </template>
      </p>
    </div>
    <v-textarea v-model="editingDescription" label="メモ" outlined :rules="[descriptionValidator]" />
  </confirm-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ConfirmDialog from '@/components/common/confirm-dialog.vue';
import { descriptionValidator } from '@/utils/binderFoldersUtils';
import { QuickAccessItemWithBibliography } from 'wklr-backend-sdk/models';

@Component({
  components: { ConfirmDialog },
})
export default class ModifyQuickAccessItemDialog extends Vue {
  $refs!: {
    editDescriptionDialog: ConfirmDialog;
  };

  descriptionValidator = descriptionValidator;

  @Prop()
  success?: () => Promise<void>;

  /** 編集対象のアイテム */
  itemToEdit: QuickAccessItemWithBibliography | null = null;

  get editDescriptionDialog(): boolean {
    return this.itemToEdit !== null;
  }
  set editDescriptionDialog(value) {
    if (!value) {
      this.itemToEdit = null;
    }
  }

  editingDescription = '';

  show(item: QuickAccessItemWithBibliography) {
    this.itemToEdit = item;
    this.editingDescription = item.description;
  }

  async updateBookmarkDescription() {
    if (!this.itemToEdit) {
      return;
    }

    const { id, updatedAt } = this.itemToEdit;

    try {
      await this.$repositories.quickAccess.modifyItem(id, this.editingDescription, updatedAt);
      this.$toast.success('クイックアクセスアイテムのメモを変更しました');
      this.editDescriptionDialog = false;
      if (this.success) {
        this.success();
      }
    } catch (error) {
      this.$refs.editDescriptionDialog.reactivate();
      console.error(error);
      this.$toast.error('クイックアクセスアイテムのメモの変更に失敗しました');
    }
  }
}
</script>
