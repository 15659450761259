<template>
  <v-select
    v-model="model"
    :items="highlightColorItems"
    item-value="id"
    dense
    outlined
    hide-details
    height="50"
    label="カラー"
    class="color-selector"
  >
    <template #item="{ item }">
      <span class="highlight-color-top" :class="`-${item.colorName}`" :aria-label="item.colorName" />
    </template>
    <template #selection="{ item }">
      <span class="highlight-color-top" :class="`-${item.colorName}`" :aria-label="item.colorName" />
    </template>
  </v-select>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { highlightColorItems } from '@/utils/binderFoldersUtils';
import { HighlightColorEnum } from 'wklr-backend-sdk/models';

@Component
export default class ColorSelector extends Vue {
  @Prop() value!: null | HighlightColorEnum;

  get model(): null | HighlightColorEnum {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }

  get highlightColorItems() {
    return highlightColorItems;
  }
}
</script>

<style lang="scss" scoped>
.color-selector {
  width: 140px;
  margin: 12px 0 24px;

  // Vuetify のスタイルを上書き
  ::v-deep .v-label {
    top: 14px !important;

    &.v-label--active {
      top: 10px !important;
    }
  }
  // Vuetify のスタイルを上書き
}

.highlight-color-top {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &.-blue {
    background-color: $highlight-blue;
  }

  &.-red {
    background-color: $highlight-red;
  }

  &.-green {
    background-color: $highlight-green;
  }

  &.-purple {
    background-color: $highlight-purple;
  }
}
</style>
