<template>
  <v-select
    v-model="orderModel"
    :items="orderMap"
    item-text="label"
    dense
    solo
    flat
    return-object
    hide-details
    class="order-selector"
    :menu-props="menuProp"
  >
    <template #prepend-item>
      <v-subheader class="order-selector-subheader">並び順</v-subheader>
    </template>
  </v-select>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';

export type OrderType<T> = { label: string; value: T };

@Component
export default class OrderSelector<T> extends Vue {
  @Prop() orderMap!: OrderType<T>[];
  @Prop() value!: OrderType<T>;

  get orderModel(): OrderType<T> {
    return this.value;
  }
  set orderModel(value) {
    this.$emit('input', value);
  }

  readonly menuProp = {
    contentClass: 'order-selector-menu',
    offsetY: true,
  };
}
</script>

<style lang="scss">
.order-selector {
  flex-grow: 0;
  flex-basis: 15em;
  width: 15em;

  // Vuetify のスタイルを上書き
  .v-input__slot {
    align-items: center;
    height: 32px;
  }

  .v-select__selection {
    order: 2;
    font-size: 14px;
  }

  .v-select__selection--comma {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .v-input__append-inner {
    .v-icon {
      margin-top: 4px !important;
    }
  }

  .v-input__control {
    min-height: 32px !important;
  }
  // Vuetify のスタイルを上書き
}

.order-selector-menu {
  max-width: 14em;
  width: 14em;

  // Vuetify のスタイルを上書き
  .v-select-list {
    padding-top: 0;
  }
  // Vuetify のスタイルを上書き

  .order-selector-subheader {
    &.v-subheader {
      // Vuetify のスタイルを上書き
      font-size: 11px !important;
      padding: 8px !important;
      height: auto !important;
      // Vuetify のスタイルを上書き
    }
  }
}
</style>
